import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ITodoReducer } from '../types/types';
import { TodoItem } from '../TodoItem/TodoItem';
import { completeTodo, deleteTodo, editTodo } from '../../redux/actions';
import './styles.css';

export const TodoList = () => {
  const state = useSelector((state: ITodoReducer) => state.todoReducer);

  const dispatch = useDispatch();

  const changeTodo = (title: string, done: boolean, id: string) => {
    dispatch(editTodo(id, done, title));
  };

  const removeTodo = (id: string) => {
    dispatch(deleteTodo(id));
  };

  const doneTodo = (id: string, done: boolean) => {
    dispatch(completeTodo(id, done));
  };

  return (
    <TransitionGroup component='ul' className='list-group'>
      {state.todos.map((todo, index) => (
        <CSSTransition timeout={800} classNames={'todo'} key={index}>
          <TodoItem
            todo={todo}
            deleteTodo={removeTodo}
            doneTodo={doneTodo}
            changeTodo={changeTodo}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
