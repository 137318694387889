import { Link } from 'react-router-dom';
import './styles.css';

export const NavBar = () => {
  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/'>
          <img
            className='img-navbar'
            src='images/navBarImg.png'
            alt='надпись в квадрате: theasket - копировальный | фото центр'
          />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link className='nav-link' aria-current='page' to='/'>
                Главная
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link ' to='contacts'>
                Контакты
              </Link>
            </li>
          </ul>
          {/* <form className='d-flex' role='search'>
            <input
              className='form-control me-2'
              type='search'
              placeholder='Искать...'
              aria-label='Search'
            />
            <button className='btn btn-outline-success' type='submit'>
              Поиск
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
};
