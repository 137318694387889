import { Helmet } from 'react-helmet';
import './styles.css';

export const Cookie = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Политика использования файлов Cookie | theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | Фото центр' />
      </Helmet>

      <div className='cookie'>
        <h1 className='text-center'>Политика использования файлов Cookie</h1>
        <h2>Что такое файлы cookie?</h2>

        <p className='p'>
          Файлы cookie - это небольшие файлы, которые обычно состоят из букв и
          цифр. Cookie-файл может отправляться в браузер вашего ПК или другого
          устройства каждый раз, когда вы посещаете определенные сайты. Цель
          файлов cookie - хранить базовую информацию, такую как предпочтения
          посетителей. Cookie будут отправляться обратно на первоначальный сайт
          при каждом вашем последующем посещении этого сайта. Cookie полезны,
          так как позволяют сайтам в течение некоторого периода времени
          распознавать ваш браузер или устройство.
        </p>
        <p className='p'>
          Большинство интернет-браузеров поддерживает cookie. Пользователи могут
          управлять браузерами, настроив их так, чтобы они отклоняли
          определенные типы файлов cookie или показывали, когда cookie
          отправляются. Большинство самых известных в мире сайтов использует
          cookie. Без файлов cookie функции или услуги, доступные на некоторых
          сайтах, могут даже не работать должным образом.
        </p>

        <h2>Как мы используем cookie?</h2>

        <p className='p'>
          Мы используем их, чтобы сайт theasket.com стал проще в использовании,
          мы стремимся адаптировать с их помощью нашу продукцию в соответствии с
          вашими интересами и потребностями. Cookie также могут быть
          использованы для ускорения вашей будущей работы на нашем сайте. Мы
          также используем cookie для компиляции анонимной информации - сводных
          статистических данных, которые позволяют нам понять, как люди
          используют наш сайт, чтобы помочь нам улучшить их структуру и
          содержание. Мы не можем идентифицировать вас лично на основании этой
          информации.
        </p>

        <h2>Какие типы cookie мы используем?</h2>

        <p className='p'>
          На сайте theasket мы используем два вида файлов cookie — "cookie
          сессии" и "постоянные cookie". Cookie сессии — это временные файлы,
          которые остаются на устройстве пока вы не покинете сайт theasket.com
          Постоянные cookie остаются на устройстве в течение длительного времени
          или пока вы вручную не удалите их (как долго cookie останется на вашем
          устройстве будет зависеть от продолжительности или «времени жизни»
          конкретного файла и настройки вашего браузера).
        </p>

        <p className='p'>
          Некоторые из посещенных страниц могут также собирать информацию,
          используя пиксельные тэги, которая может быть передана третьим
          сторонам, которые непосредственно поддерживают нашу рекламную
          деятельность и разрабатывают веб-сайты. Например, информация о
          посетителях веб-сайта theasket.com может использоваться совместно с
          третьим лицом - рекламным агентством, чтобы улучшить целевую баннерную
          рекламу на нашем сайте. Эта информация, однако, не пригодна для
          процедуры идентификации, хотя она может быть связана с вашей личной
          информацией.
        </p>

        <h2>Как отключить и/или удалить файлы cookie?</h2>

        <p className='p'>
          Прежде всего, пожалуйста, учтите, что большинство браузеров принимает
          cookie по умолчанию. Вы имеете право принять или отклонить передачу
          файлов cookie на ваш ПК или другое устройство, в любое время изменив
          настройки в вашем браузере в соответствии с вашими предпочтениями в
          отношении файлов cookie. Вы можете контролировать и/или удалять файлы
          cookie по вашему усмотрению. Чтобы узнать об этом больше, посетите,
          пожалуйста, https://aboutcookies.org. Вы можете удалить все файлы
          cookie, уже имеющиеся на вашем ПК или другом устройстве, и настроить
          большинство браузеров на отказ принимать файлы cookie. Однако в этом
          случае вам придется вручную корректировать некоторые установки при
          каждом посещении сайта. Из-за этого некоторые сервисы и функции могут
          не работать должным образом.
        </p>

        <p className='p'>
          Для отказа от отслеживания сервисом Google Analytics на всех сайтах
          посетите https://tools.google.com/dlpage/gaoptout.
        </p>
      </div>
    </>
  );
};
