import { Helmet } from 'react-helmet';

export const HeaderTodo = () => {
  return (
    <header style={{ backgroundColor: '#0071F7', color: 'white' }}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Список дел | копировального | фото центра Аскет - theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta
          name='description'
          content='Список дел | Копировальный | фото центр Аскет'
        />
      </Helmet>

      <div className='container pt-3 pb-3 d-flex justify-content-center text-center'>
        <div className='container'>
          <h1>Список моих дел</h1>
        </div>
      </div>
    </header>
  );
};
