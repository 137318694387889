import axios from 'axios';
import { ITodo, ITodoState } from '../../components/todo/types/types';

export class TodoApi {
  static async getTodos(): Promise<ITodoState[]> {
    const res = await axios.get(`${process.env.REACT_APP_API_URL_TODOS}`);
    return res.data;
  }

  static async createTodo(todo: Partial<ITodo>): Promise<ITodoState[]> {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL_TODOS}`,
      todo
    );
    return res.data;
  }

  static async deleteTodo(id: string): Promise<void> {
    await axios.delete(`${process.env.REACT_APP_API_URL_TODOS}/${id}`);
  }

  static async completeTodo(todo: Partial<ITodo>): Promise<void> {
    await axios.patch(
      `${process.env.REACT_APP_API_URL_TODOS}/${todo.id}`,
      todo
    );
  }

  static async editTodo(todo: Partial<ITodo>): Promise<ITodo> {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_URL_TODOS}/${todo.id}`,
      todo
    );
    return res.data[1][0];
  }
}
