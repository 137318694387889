import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

export const Sitemap = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Карта сайта копировального | фото центра Аскет | theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | фото центр' />
      </Helmet>

      <ul>
        <li>
          <Link to='/'>Главная стр.</Link>
        </li>
        <li>
          <Link to='/about'>О нас</Link>
        </li>
        <li>
          <Link to='/contacts'>Контакты</Link>
        </li>
        <li>
          <Link to='/delivery'>Забрать заказ</Link>
        </li>
        <li>
          <Link to='/sitemap'>Карта сайта</Link>
        </li>
        <li>
          <Link to='/cookie'>Cookie</Link>
        </li>
        <li>
          <Link to='/todo'>Список дел</Link>
        </li>
      </ul>
    </>
  );
};
