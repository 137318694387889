import { Link, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HeaderTodo } from './components/todo/Header/HeaderTodo';
import { HeaderFeedback } from './components/feedback/Header/HeaderFeedback';
import { TodoForm } from './components/todo/TodoForm/TodoForm';
import { TodoList } from './components/todo/TodoList/TodoList';
import { getTodos } from './components/redux/actions';
import { Sitemap } from './components/homepage/Sitemap/Sitemap';
import { About } from './pages/About/About';
import { Contacts } from './pages/Contacts/Contacts';
import { Cookie } from './components/homepage/Cookie/Cookie';
import { Delivery } from './components/homepage/Delivery/Delivery';
import { MainPage } from './pages/MainPage/MainPage';
import { Calculate } from './pages/Calculate/Calculate';
import { NavBar } from './components/homepage/NavBar/NavBar';
import { Leaflet } from './pages/Leaflet/Leaflet';
import CookieConsent from 'react-cookie-consent';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodos());
  }, [dispatch]);

  return (
    <>
      <NavBar />

      <Routes>
        <Route path='/' element={<MainPage />} />

        <Route path='/calculate' element={<Calculate />} />

        <Route path='/about' element={<About />} />

        <Route path='/contacts' element={<Contacts />} />

        <Route path='/delivery' element={<Delivery />} />

        <Route path='/sitemap' element={<Sitemap />} />

        <Route path='/cookie' element={<Cookie />} />

        <Route path='/leaflet' element={<Leaflet />} />

        <Route
          path='/todo'
          element={
            <>
              <HeaderTodo />
              <TodoForm />
              <TodoList />
            </>
          }
        />
        <Route
          path='/feedback'
          element={
            <>
              <HeaderFeedback />
              <TodoForm />
              <TodoList />
            </>
          }
        />
      </Routes>
      <CookieConsent
        debug={false}
        buttonText='Понятно'
        style={{ background: '#F0F0F0', color: 'black', textAlign: 'center' }}
        buttonStyle={{
          color: 'silver',
          fontSize: '14px',
          borderRadius: '8px',
          background: '#0A4500',
        }}
        expires={150}
      >
        <p>
          Мы используем Cookies, чтобы сделать сайт удобнее для вас. Подробнее в согласии и политике.
        </p>
        <Link
          to='https://theasket.com/cookie'
          target='_blank'
          className='me-4 text-reset'
        >
          О файлах Cookies
        </Link>
      </CookieConsent>
    </>
  );
}

export default App;
