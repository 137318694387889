import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const Leaflet = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>О копировальном | фото центре Аскет | theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | фото центр' />
      </Helmet>

      <div style={{ marginBottom: '50px' }}></div>
      <h1>
        Для расчёта стоимости изготовления продукции, напишите нам
        в телеграмм{' '}
        <Link
          to='https://t.me/the_asket'
          target='_blank'
          className='me-4 text-reset'
          style={{ textDecoration: 'none' }}
        >
          <span
            className='fab fa-telegram'
            style={{ color: 'blue'}}
          >
            @the_asket,
          </span>
        </Link>
        что и в каком количестве, вы планируете заказать.
      </h1>
    </>
  );
};
