import { Helmet } from 'react-helmet';

export const Delivery = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Забрать готовый заказ | Копировальный | Фото центр Аскет - theasket.com
        </title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | фото центр' />
      </Helmet>
      <h1>Как и когда можно забрать готовый заказ?</h1>
      <h4>
        Дождитесь автоматического письма о готовности заказа на Вашу электронную
        почту.
      </h4>
      <h4>
        Примечание: если, вы не получили, в обговоренный с менеджером срок,
        письмо о готовности заказа - то проверьте папку "СПАМ". В случае
        нахождения там письма - пометьте письмо значком "НЕ СПАМ".
      </h4>
      <h4>
        После получения письма о готовности, забрать заказ можно в любой будний
        день с 09:00 до 17:30.
      </h4>
      <h4>
        Также, Вы можете самостоятельно заказать доставку по г. Самаре
        Яндекс-курьером "От двери до двери" или доставку СДЭК в любой регион
        России и зарубежья.
      </h4>
      <h4>
        При заказе доставки ОБЯЗАТЕЛЬНО указывайте 3 параметра:
        <br />
        1) Номер заказа (без номера заказа, непонятно, что выдавать курьеру).
        <br />
        2) Свой номер телефона.
        <br />
        3) Наш номер телефона 8-937-989-01-01.
      </h4>
    </>
  );
};
