import { Helmet } from 'react-helmet';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import './styles.css';

export const YandexMaps = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Контакты копировального | фото центра Аскет | theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | фото центр' />
      </Helmet>

      <YMaps
        location={{ center: [53.20775, 50.178562], zoom: 15 }}
        mode='vector'
      >
        <Map
          className='map'
          defaultState={{ center: [53.20775, 50.178562], zoom: 15 }}
          mode='vector'
        >
          <Placemark geometry={[53.20775, 50.178562]} />
        </Map>
      </YMaps>
    </>
  );
};
