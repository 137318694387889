/* eslint-disable jsx-a11y/alt-text */
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Footer } from '../../components/homepage/Footer/footer';
import { YandexMaps } from '../../components/yandexMaps/YandexMaps';
import './styles.css';

export const Contacts = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Контакты копировального | фото центра Аскет | theasket.com
        </title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Копировальный | фото центр Аскет' />
      </Helmet>
      <div className='contacts-data'>
        Контактные данные <span style={{ color: 'red' }}>А</span>скет
      </div>
      <div className='questions'>
        Есть вопросы? Нажимай на значок{' '}
        <span>
          <Link
            to='https://t.me/the_asket'
            target='_blank'
            className='text-reset'
          >
            <i className='our-style-icons-contacts fas fa-phone me-2'>
              <img
                className='our-img-zoom our-style-icons-contacts fas fa-phone me-2'
                src={'images/SocialNetworks/tlg_contacts.png'}
                alt='Кликабельная иконка телеграмм'
              />
            </i>
          </Link>
          <Link
            to='https://t.me/the_asket'
            target='_blank'
            className='text-reset'
          ></Link>
        </span>
        и напиши нам в{' '}
        <Link
          to='https://t.me/the_asket'
          target='_blank'
          className='my-text-reset'
        >
          <span className='tlg-contacts'>Telegram</span>
        </Link>
      </div>
      <div className='phone'>8-927-989-01-01</div>

      <div className='operating-mode'>Режим работы офиса</div>
      <div className='working-hours'>
        <b>по будням без перерыва:</b>
        <b className='working-day'> с 09:00 до 17:30</b>
        <br />
        <b>суббота, воскресенье:</b> <b className='rest-day'>выходной</b>
      </div>

      <div className='our-address'>Наш адрес</div>
      <div className='our-address-font'>
        443045, <span className='no-space'>г. Самара</span>,{' '}
        <span className='no-space'>ул. Революционная 70Е</span>,{' '}
        <span className='no-space'>этаж 2</span>,{' '}
        <span className='no-space'>офис 1</span>
      </div>
      <div className='photo-address'>
        <img
          className='photo-address-img'
          src={'images/address.webp'}
          alt='фотография здания в городе Самаре на улице Революционная 70Е'
        />
      </div>
      <YandexMaps />
      <Footer />
    </>
  );
};
