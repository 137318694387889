import { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTodo, hideAlert, showAlert } from '../../redux/actions/index';
import { IAlertReducer } from '../types/types';
import { Alert } from '../../todo/Alert/Alert';
import './styles.css';

export const TodoForm = () => {
  let [title, setTitle] = useState('');
  const alertState = useSelector((state: IAlertReducer) => state.alertReducer);
  const dispatch = useDispatch();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!title.trim()) {
      dispatch(showAlert('Название дела не может быть пустым', 'warning'));
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    }

    dispatch(createTodo(title));
    setTitle('');
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {alertState.alertText?.length > 0 && <Alert props={alertState} />}
        <div className='mb-3 d-flex align-items-end justify-content-between'>
          <div
            className='form-group'
            style={{ width: '92%', marginRight: '10px' }}
          >
            <label
              htmlFor='Введите название дела'
              className='form-label'
            ></label>
            <input
              autoFocus={true}
              placeholder='Введите название дела'
              onChange={handleChangeInputValue}
              type='text'
              maxLength={130}
              className='form-control'
              value={title}
            />
          </div>
          <button className='btn btn-success'>создать</button>
        </div>
      </form>
      <h2 className='pt-3 pb-2'>Новые дела:</h2>
    </>
  );
};
