import { Helmet } from 'react-helmet';

export const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>О копировальном | фото центре Аскет | theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копировальный | фото центр' />
      </Helmet>
      <h1>Копировальный | Фото центр Аскет</h1>
      <h3>
        Широкоформатная, цифровая, офсетная печать, ризография и плоттерная
        резка. Изготавливаем листовки, визитки, буклеты, брошюры, бирки,
        коробочки, блокноты, афиши, плакаты, картины на холсте, бланки,
        открытки, пригласительные, сертификаты, дипломы, календари, стикеры,
        наклейки, этикетки, флаеры, вывески, таблички, баннеры, фотозоны (press
        wall), пауки, ролл аппы (roll up) и много другое.
      </h3>
      <br />
      <h3>Для расчёта стоимости напишите нам в телеграм: @the_asket.</h3>
    </>
  );
};
