import { Helmet } from 'react-helmet';
import { Footer } from '../../components/homepage/Footer/footer';
import './styles.css';

export const MainPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Копировальный | Фото центр Аскет - theasket.com</title>
        <meta
          name='keywords'
          content='Фото, печать документов, копирование, ламинирование, брошюровка, дипломы, курсовые, переплёт, сканирование'
        />
        <meta name='description' content='Аскет копицентр' />
      </Helmet>
      <h2 className='text-center google-fonts first-inscription'>
        <span style={{ color: '#8B00FF' }}>Копировальный | фото центр</span>{' '}
        <span style={{ color: 'red' }}>А</span>
        <span style={{ color: '#8B00FF' }}>скет</span>
        <div className='second-inscription'>
          Фото, печать документов, копирование, ламинирование, брошюровка,
          переплёт, сканирование и многое другое
        </div>
      </h2>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ПЕЧАТЬ ДОКУМЕНТОВ:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>1</th>
              <td>Печать А4 (ч/б)</td>
              <td>1 стр.</td>
              <td>12 руб.</td>
            </tr>
            <tr>
              <th scope='row'>2</th>
              <td>Печать А4 (цвет.)</td>
              <td>1 стр.</td>
              <td>30 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                КОПИРОВАНИЕ ДОКУМЕНТОВ:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>3</th>
              <td>Копирование А4 (ч/б)</td>
              <td>1 стр.</td>
              <td>12 руб.</td>
            </tr>
            <tr>
              <th scope='row'>4</th>
              <td>Копирование А4 (цвет.)</td>
              <td>1 стр.</td>
              <td>30 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                СКАНИРОВАНИЕ ДОКУМЕНТОВ:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>5</th>
              <td>Сканирование А4</td>
              <td>1 стр.</td>
              <td>20 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                РЕДАКТИРОВАНИЕ ДОКУМЕНТОВ:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>6</th>
              <td>
                Редактирование документа
              </td>
              <td>1 стр.</td>
              <td>100 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ПЕЧАТЬ ФОТОГРАФИЙ РАЗМЕРОМ 100х150мм:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>7</th>
              <td>Печать фото</td>
              <td> 1-30 шт.</td>
              <td>30 руб.</td>
            </tr>
            <tr>
              <th scope='row'>8</th>
              <td>Печать фото</td>
              <td> 31-70 шт.</td>
              <td>27 руб.</td>
            </tr>
            <tr>
              <th scope='row'>9</th>
              <td>Печать фото</td>
              <td> от 70 шт.</td>
              <td>24 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ПЕЧАТЬ ФОТОГРАФИЙ РАЗМЕРОМ 130х180мм:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>10</th>
              <td>Печать фото</td>
              <td> 1-30 шт.</td>
              <td>35 руб.</td>
            </tr>
            <tr>
              <th scope='row'>11</th>
              <td>Печать фото</td>
              <td> 31-70 шт.</td>
              <td>33 руб.</td>
            </tr>
            <tr>
              <th scope='row'>12</th>
              <td>Печать фото</td>
              <td> от 70 шт.</td>
              <td>31 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ПЕЧАТЬ ФОТОГРАФИЙ РАЗМЕРОМ 150х210мм:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>13</th>
              <td>Печать фото</td>
              <td> 1-30 шт.</td>
              <td>45 руб.</td>
            </tr>
            <tr>
              <th scope='row'>14</th>
              <td>Печать фото</td>
              <td> 31-70 шт.</td>
              <td>40 руб.</td>
            </tr>
            <tr>
              <th scope='row'>15</th>
              <td>Печать фото</td>
              <td> от 70 шт.</td>
              <td>35 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ПЕЧАТЬ ФОТОГРАФИЙ РАЗМЕРОМ 210х300мм:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>16</th>
              <td>Печать фото</td>
              <td> 1-30 шт.</td>
              <td>75 руб.</td>
            </tr>
            <tr>
              <th scope='row'>17</th>
              <td>Печать фото</td>
              <td> 31-70 шт.</td>
              <td>72 руб.</td>
            </tr>
            <tr>
              <th scope='row'>18</th>
              <td>Печать фото</td>
              <td> от 70 шт.</td>
              <td>69 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='my-prices-table'>
        <table className='table table-striped google-fonts'>
          <thead>
            <tr className='table-success text-center fs-2'>
              <th scope='col' colSpan={4}>
                ЛАМИНИРОВАНИЕ:
              </th>
            </tr>
          </thead>
          <tbody className='fs-5'>
            <tr>
              <th scope='row'>19</th>
              <td>А6</td>
              <td>1 лист</td>
              <td>45 руб.</td>
            </tr>
            <tr>
              <th scope='row'>20</th>
              <td>А5</td>
              <td>1 лист</td>
              <td>55 руб.</td>
            </tr>
            <tr>
              <th scope='row'>21</th>
              <td>А4</td>
              <td>1 лист</td>
              <td>75 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Footer />
    </>
  );
};
