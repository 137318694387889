import { Link } from 'react-router-dom';
import './styles.css';

export const Footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}
      <footer className='text-center text-lg-start bg-body-tertiary text-muted'>
        {/* <!-- Section: Social media --> */}
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          {/* <!-- Left --> */}
          <div className='me-5 d-none d-lg-block'>
            <div className='our-social-network'>Мы в социальных сетях:</div>
          </div>
          {/* <!-- Left --> */}

          {/* <!-- Right --> */}
          <div>
            <Link
              to='https://t.me/the_asket'
              target='_blank'
              className='me-4 text-reset'
            >
              <i className='fab fa-telegram'>
                <img
                  src={'images/SocialNetworks/tlg_social.png'}
                  alt='Кликабельная иконка телеграм'
                  className='our-img-zoom'
                />
              </i>
            </Link>
            <Link
              to='https://api.whatsapp.com/send?phone=79379890101'
              target='_blank'
              className='me-4 text-reset'
            >
              <i className='fab fa-whatsApp'>
                <img
                  src={'images/SocialNetworks/whatsApp.png'}
                  alt='Кликабельная иконка WhatsApp'
                  className='our-img-zoom'
                />
              </i>
            </Link>
            <Link
              to='https://msng.link/o?79379890101=vi'
              target='_blank'
              className='me-4 text-reset'
            >
              <i className='fab fa-viber'>
                <img
                  src={'images/SocialNetworks/viber.png'}
                  alt='Кликабельная иконка Viber'
                  className='our-img-zoom'
                />
              </i>
            </Link>
            <Link
              to='https://vk.com/the_asket'
              target='_blank'
              className='text-reset'
            >
              <i className='fab fa-vkontakte'>
                <img
                  src={'images/SocialNetworks/vk.png'}
                  alt='Иконка Вконтакте'
                  className='our-img-zoom'
                />
              </i>
            </Link>
          </div>
          {/* <!-- Right --> */}
        </section>
        {/* <!-- Section: Social media --> */}

        {/* <!-- Section: Links  --> */}
        <section className=''>
          <div className='container text-center text-md-start mt-5'>
            {/* <!-- Grid row --> */}
            <div className='row mt-3'>
              {/* <!-- Grid column --> */}
              <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
                {/* <!-- Content --> */}
                <h6 className='text-uppercase fw-bold mb-4'>
                  <i className='fas fa-gem'></i>Копировальный | фото центр Аскет
                </h6>
                <p>
                  <Link to='#!' className='text-reset'>
                    Политика конфиденциальности
                  </Link>
                </p>
                <p>
                  <Link to='#!' className='text-reset'>
                    Пользовательское соглашение
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
                {/* <!-- Links --> */}
                <h6 className='text-uppercase fw-bold mb-4'>
                  ПОПУЛЯРНЫЕ УСЛУГИ
                </h6>
                <p>
                  <Link to='/' className='text-reset'>
                    Печать документов
                  </Link>
                </p>
                <p>
                  <Link to='#!' className='text-reset'>
                    Копирование документов
                  </Link>
                </p>
                <p>
                  <Link to='#!' className='text-reset'>
                    Сканирование документов
                  </Link>
                </p>
                <p>
                  <Link to='#!' className='text-reset'>
                    Редактирование документов
                  </Link>
                </p>
                <p>
                  <Link to='#!' className='text-reset'>
                    Печать фотографий
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
                {/* <!-- Links --> */}
                <h6 className='text-uppercase fw-bold mb-4'>РАЗНОЕ</h6>
                {/* <p>
                  <Link to='todo' className='text-reset'>
                    Список дел
                  </Link>
                </p>
                <p>
                  <Link to='faq' className='text-reset'>
                    Вопрос-ответ
                  </Link>
                </p>
                <p>
                  <Link to='sitemap' className='text-reset'>
                    Карта сайта
                  </Link>
                </p> */}
                <p>
                  <Link to='cookie' className='text-reset'>
                    О файлах Cookie
                  </Link>
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                {/* <!-- Links --> */}
                <h6 className='text-uppercase fw-bold mb-4'>КОНТАКТЫ</h6>
                <p>
                  <Link
                    to='https://t.me/the_asket'
                    target='_blank'
                    className='text-reset'
                  >
                    <i className='our-style-icons fas fa-phone me-2'>
                      <img
                        className='our-img-zoom our-style-icons fas fa-phone'
                        src={'images/SocialNetworks/tlg_contacts.png'}
                        alt='Кликабельная иконка телеграмм'
                      />
                    </i>
                    <i className='our-style-icons fas fa-print me-2'>
                      <img
                        className='our-phone'
                        src={'images/SocialNetworks/phone.png'}
                        alt='Иконка в виде телефонной трубки, символизирует городской номер телефона'
                      />
                    </i>
                  </Link>
                  <Link
                    to='https://t.me/the_asket'
                    target='_blank'
                    className='text-reset cell-phone'
                  >
                    <span>+7-937-989-01-01</span>
                  </Link>
                </p>
                <p>
                  <i className='our-style-icons fas fa-print me-2'>
                    <img
                      className='our-phone'
                      src={'images/SocialNetworks/phone.png'}
                      alt='Иконка в виде телефонной трубки, символизирует городской номер телефона'
                    />
                  </i>
                  <span>+7 (846) 989-01-01</span>
                </p>
                <p>
                  <i className='our-style-icons fas fa-envelope me-2'>
                    <img
                      src={'images/SocialNetworks/email.png'}
                      alt='Иконка в виде конвертика, символизирует электронную почту'
                      className='our-email-img'
                    />
                  </i>
                  info@theasket.com
                </p>
                <p>
                  <i className='our-style-home fas fa-home me-2'>
                    <img
                      className='office'
                      src={'images/SocialNetworks/office.png'}
                      alt='Иконка в виде здания, символизирует адрес офиса'
                    />
                  </i>
                  443045, <span className='no-space'>г. Самара</span>,{' '}
                  <span className='no-space'>ул. Революционная 70Е</span>,{' '}
                  <span className='no-space'>этаж 2</span>,{' '}
                  <span className='no-space'>офис 1</span>
                </p>
              </div>
              {/* <!-- Grid column --> */}
            </div>
            {/* <!-- Grid row --> */}
          </div>
        </section>
        {/* <!-- Section: Links  --> */}

        {/* <!-- Copyright --> */}

        <div
          className='text-center p-4'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        >
          © Копировальный | Фото центр "Аскет"
          <br />
          ИП Семенов А.С., ИНН 637602120871, ЕГРИП 324632700149323
          <br />
          2024 г., с любовью, Ваш Аскет{' '}
          <img
            src={'images/SocialNetworks/heart.png'}
            alt='Иконка в виде сердца, символизирует любовь'
            className='our-img-zoom heart'
          />
        </div>
        {/* <!-- Copyright --> */}
      </footer>
      {/* <!-- Footer --> */}
    </>
  );
};
